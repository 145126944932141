import React, { useContext, useState, Fragment } from "react";

import { APIContext, PopUpContext } from "../../API";

import { Status, StatusButton } from "../misc";

export default function ResendTransaction(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  function submit() {
    if (status) return;

    setError(null);
    setStatus("Resending");

    API.resendTransaction(props.transaction, (err, response) => {
      if (err) {
        setStatus(null);
        return setError(
          err.response?.data?.msg ||
            `Something went wrong.  "${props.transaction._id}" was not resent.`
        );
      }
    });
  }

  return (
    <Fragment>
      <p className="mb-2">
        Are you sure you would like to resend transaction{" "}
        {props.transaction._id} to {props.transaction.name} [
        {props.transaction.customer_email}]?
      </p>
      <Status error={error} />

      <div className="button-group">
        <button className="small-button _pop-up" onClick={() => setPopUp(null)}>
          Cancel
        </button>

        <StatusButton
          status={status}
          className={`button _pop-up ${status && "_disabled"}`}
          onClick={submit}
          text="Resend"
        />
      </div>
    </Fragment>
  );
}
