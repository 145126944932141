import { useContext, useState, useEffect } from "react";

import { APIContext } from "../../API";

import "./CartRow.css";

export default function CartRow(props) {
  const API = useContext(APIContext);

  const [product, setProduct] = useState(
    API.getProductsById([props.product])[0] || null
  );

  useEffect(() => {});

  return product ? (
    <div className="flex">
      <img
        className="cart-row-image"
        src={product.thumbnail?.location}
        alt=""
        height="100"
        width="100"
      />
      <div className="cart-row-col">
        <div className="cart-row-title">{product.title}</div>
        <div className="cart-row-subtitle flex">
          <span className="cart-row-type">{product.type}</span>
          {props.remove && (
            <span className="cart-row-remove hover" onClick={props.remove}>
              Remove
            </span>
          )}
        </div>
      </div>
      {product.price && (
        <span className="cart-row-price">${product.price}</span>
      )}
    </div>
  ) : (
    <div className="error _cart-row">Product {props.product} not found.</div>
  );

  // return (
  //   <div className="transactions-grid-cart-row">
  //     <div>{props.index}</div>
  //     <div>{props.product?.title || "Product not found"}</div>
  //     <div>{props.product?.price || "?"}</div>
  //   </div>
  // );
}
