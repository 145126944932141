import React, { useContext } from "react";

import { ProductContext, TransactionContext } from "../../API";

import { Status } from "../misc";

import "./NotificationManager.css";

export default function NotificationManager() {
  const { productsStatus } = useContext(ProductContext);
  const { transactionsStatus, transactionsProgress } =
    useContext(TransactionContext);

  return (
    <div id="notification-manager">
      {(productsStatus || transactionsStatus) && (
        <div className="notification">
          <div className="notification-message status">Fetching Data</div>
          <Status progress={transactionsProgress} />
        </div>
      )}
    </div>
  );
}
