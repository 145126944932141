import urlSlug from "url-slug";

export function niceDate(date) {
  const d = new Date(date);
  return (
    d.toDateString().substring(4, 10) +
    ", " +
    d.toLocaleTimeString().split(":").slice(0, 2).join(":") +
    " " +
    d.toLocaleTimeString().split(" ")[1]
  );
}

export function ISODate(date) {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
}

export function parseDescription(description) {
  let parsed = description.split(/\r?\n/);
  return parsed;
}

export function makeDescription(descriptionArray) {
  let description = "";
  for (let i = 0; i < descriptionArray.length; i++) {
    description += descriptionArray[i];
    if (i + 1 !== descriptionArray.length) {
      description += "\n";
    }
  }
  return description;
}

export function generateTags(title) {
  let tags = [];
  function tagCheck(tag) {
    if (
      title
        .replace(/['']+/gi, "")
        .replace(/[^a-z0-9]+/gi, " ")
        .toLowerCase()
        .includes(tag)
    ) {
      tags.push(tag);
    }
  }
  [
    "deep",
    "deep house",
    "minimal",
    "industrial",
    "techno",
    "modern",
    "rave",
    "hard",
    "tutorial",
    "acid",
    "jungle",
    "tech house",
    "disco",
    "drums",
    "drumcode",
    "piv",
    "dark",
    "loops",
    "groovy",
    "melodic",
    "atmospheric",
    "garage",
    "future",
    "lo-fi",
    "progressive",
    "cosmic",
    "trance",
    "classic",
    "presets",
    "fx",
    "full track",
    "template",
    "project file",
    "samples",
    "midi",
    "melody",
    "arrangement",
    "90s",
    "80s",
    "70s",
    "2000s",
    "house",
    "breakbeat",
  ].forEach((tag) => tagCheck(tag));
  return tags;
}

export function generateSlug(title) {
  return urlSlug(title.split("[")[0].split("(")[0]);
}

export function generateType(title) {
  if (title.toLowerCase().includes("tutorial files")) {
    return "Tutorial Files";
  } else if (title.toLowerCase().includes("vol")) {
    return "Sample Pack";
  } else if (title.toLowerCase().includes("definitive")) {
    return "Sample Pack";
  } else if (title.toLowerCase().includes("project")) {
    return "Project File";
  } else if (title.toLowerCase().includes("full")) {
    return "Full Track";
  } else if (title.toLowerCase().includes("template")) {
    return "Template";
  } else {
    return "Sample Pack";
  }
}

export function getYoutubeId(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url?.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
}

export function makeSelectOption(value) {
  return value ? { label: value, value: value.toLowerCase() } : null;
}

export function makeSelectOptions(values) {
  let options = [];
  if (values.length > 0) {
    values.forEach((value) => options.push(makeSelectOption(value)));
  }
  return options;
}

export function getSelectValues(options) {
  let tags = [];
  if (options) {
    options.forEach((option) => tags.push(option.value.toLowerCase()));
  }
  return tags;
}

export const getCSS = () => {
  const html = document.querySelector("html");
  return {
    text: getComputedStyle(html).getPropertyValue("--text"),
    accent: getComputedStyle(html).getPropertyValue("--accent"),
    border: getComputedStyle(html).getPropertyValue("--border"),
    radius: getComputedStyle(html).getPropertyValue("--radius"),
    background: getComputedStyle(html).getPropertyValue("--background"),
  };
};

export function getSelectStyles() {
  const css = getCSS();
  return {
    container: (styles) => ({
      ...styles,
      minWidth: "10rem",
      flexGrow: 1,
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: `1px solid ${css.border}`,
      borderRadius: css.radius,
      boxShadow: "none",
      fontSize: ".8rem",
      minHeight: "unset",
      padding: ".5rem",
      "&:hover": {},
    }),
    singleValue: (styles) => ({ ...styles, color: "black" }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      height: "1rem",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        backgroundColor: "#cccccc",
      },
    }),
    input: (styles) => ({ ...styles, height: "1rem" }),
    placeholder: (styles) => ({
      ...styles,
      color: css.accent,
      margin: "unset",
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
    indicatorsContainer: (styles) => ({
      ...styles,
      height: "1rem",
      alignSelf: "middle",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 0,
    }),
    option: () => ({
      cursor: "pointer",
      padding: ".5rem",
      "&:hover": {
        color: css.accent,
      },
    }),
  };
}

export function toPrice(price) {
  return price ? Math.trunc(100 * Number(price)) / 100 : "";
}

export function testEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function createDateRange(startDate, endDate) {
  if (endDate < startDate) return [];

  const range = [];
  let currentDate = new Date(startDate);
  while (currentDate < endDate) {
    range.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return range;
}
