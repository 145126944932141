import React, { useContext, useState } from "react";

import { APIContext } from "../API";

import { CouponForm } from "../components/coupons";
import { Status, StatusButton } from "../components/misc";

export default function AddCoupon(props) {
  const API = useContext(APIContext);

  const [coupon, setCoupon] = useState({
    code: "",
    maxUses: "",
    startDate: null,
    endDate: null,
    samples: [],
    percentageDiscount: "",
    flatDiscount: "",
    threshold: "",
  });
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  function submit() {
    if (status) {
      return;
    }

    setError(null);

    if (!coupon.code) {
      return setError("Missing code");
    }

    if (!coupon.startDate) {
      return setError("Missing start date");
    }

    if (!coupon.endDate) {
      return setError("Missing end date");
    }

    if (!coupon.flatDiscount && !coupon.percentageDiscount) {
      return setError("Missing discount");
    }

    setStatus("sending");

    API.addCoupon(coupon, (err, response) => {
      setStatus(null);
      if (err) {
        return setError(err.response?.data?.msg || "Something went wrong.");
      }
      props.close && props.close();
    });
  }

  return (
    <div className="add-coupon">
      <h1 className="mb-2">Add Coupon</h1>
      <div className="block">
        <CouponForm coupon={coupon} setCoupon={setCoupon} />
        <Status error={error} />
        <StatusButton
          text="Submit"
          className="button"
          status={status}
          onClick={submit}
        />
      </div>
    </div>
  );
}
