import React, { useContext, useState } from "react";

import { APIContext, UserContext } from "../../API";

import { Form, Input } from "../inputs";
import { StatusButton } from "..//misc";
import { PopUp } from "./PopUp";

export default function LogInRender() {
  const { token } = useContext(UserContext);

  return token ? null : (
    <PopUp>
      <LogIn />
    </PopUp>
  );
}

function LogIn() {
  const API = useContext(APIContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  function logIn() {
    if (status) return;

    setError(null);
    setStatus("Logging in");

    if (!password) {
      setStatus(null);
      return setError("Please enter your password.");
    }
    API.logIn(email, password, (err, response) => {
      if (err) {
        setStatus(null);
        return setError(err?.response?.data?.msg || "Try again");
      }
    });
  }

  return (
    <Form error={error}>
      <h2>Welcome</h2>
      <Input
        className="log-in-input"
        name="Email"
        value={email}
        setValue={setEmail}
      />
      <Input
        className="log-in-input"
        name="Password"
        type="password"
        value={password}
        setValue={setPassword}
      />
      <StatusButton
        className={`button ${status && "_disabled"}`}
        text="Log In"
        onClick={logIn}
        status={status}
      />
    </Form>
  );
}
