import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TransactionContext } from "../../API";

import "./DateRange.css";

export default function DateRange() {
  const { startDate, setStartDate, stopDate, setStopDate } =
    useContext(TransactionContext);

  return (
    <div className="date-range flex small">
      <b>Dates:</b>
      <div>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={startDate}
          onChange={(sel) => {
            const start = new Date(sel);
            start.setHours(0, 0, 0, 0);
            setStartDate(start);
          }}
          className="date-picker date-range"
          placeholderText="Start Date"
        />
      </div>
      <div>
        <DatePicker
          dateFormat="MM/dd/yyyy"
          selected={stopDate}
          onChange={(sel) => {
            const stop = new Date(sel);
            stop.setHours(23, 59, 59, 999);
            setStopDate(stop);
          }}
          className="date-picker date-range"
          placeholderText="End Date"
        />
      </div>
    </div>
  );
}

//  switch (time) {
//    case "week":
//      date_start.setDate(date_start.getDate() - date_start.getDay());
//      date_start.setHours(0, 0, 0, 0);
//      break;
//    case "month":
//      date_start = new Date(date_start.getFullYear(), date_start.getMonth(), 1);
//      break;
//    case "year":
//      date_start = new Date(date_start.getFullYear(), 0, 1);
//      break;
//    default:
//      date_start = new Date(0);
//  }
