import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { APIContext } from "../API";

import { Status, StatusButton } from "../components/misc";
import {
  ProductPreview,
  InfoForm,
  ThumbnailForm,
  DemoForm,
  FileForm,
  Stepper,
} from "../components/products";

export default function AddProduct() {
  const navigate = useNavigate();

  const API = useContext(APIContext);

  const [step, setStep] = useState(0);
  const [info, setInfo] = useState({
    title: "",
    price: "",
    release_date: null,
    slug: "",
    type: "",
    tags: [],
    youtube_url: "",
    description: [],
  });
  const [thumbnail, setThumbnail] = useState(null);
  const [demos, setDemos] = useState([]);
  const [files, setFiles] = useState([]);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => setError(null), [info, thumbnail, demos, files]);

  function imageSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      if (e.target.files[0].type.substring(0, 5) !== "image") {
        return setError("Invalid thumbnail file type.");
      }

      setThumbnail(e.target.files[0]);
    }
  }

  function audioSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      if (e.target.files[0].type.substring(0, 5) !== "audio") {
        return setError("Invalid demo file type.");
      }

      setDemos([
        ...demos,
        {
          name: e.target.files[0].name.split(".")[0],
          audio: e.target.files[0],
        },
      ]);
    }
  }

  function setDemoName(i, name) {
    let _demos = [...demos];
    _demos[i] = { ..._demos[i], name };
    setDemos(_demos);
  }

  function removeDemo(i) {
    let _demos = [...demos];
    _demos.splice(i, 1);
    setDemos(_demos);
  }

  function fileSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      setFiles([...files, e.target.files[0]]);
    }
  }

  function removeFile(i) {
    let _files = [...files];
    _files.splice(i, 1);
    setFiles(_files);
  }

  function switchStep(n) {
    setError(null);
    if (status) return;

    if (step === 0) {
      if (!info.title) {
        return setError("Missing title");
      }

      if (!info.price) {
        return setError("Missing price");
      } else if (typeof info.price !== "number") {
        return setError("Invalid price");
      }

      if (!info.type) {
        return setError("Missing type");
      }

      if (!info.release_date) {
        return setError("Missing Release Date");
      }

      if (!info.slug) {
        return setError("Missing URL");
      } else if (!info.slug.match(/^\w+([-_]\w+)*$/)) {
        return setError("Invalid URL");
      }

      if (info.tags.length === 0) {
        return setError("Missing Tags");
      }
    }

    if (step === 1) {
      if (!thumbnail) {
        return setError("Missing Thumbnail");
      }
    }

    if (step === 2) {
      if (demos.length < 1) {
        return setError("Missing Demos");
      } else {
        for (let demo of Object.values(demos)) {
          if (demo.name === "") {
            return setError("Missing Demo Name");
          }
        }
      }
    }

    if (step === 3) {
      if (files.length < 1) {
        return setError("Missing Files");
      }
    }

    if (n === 5) {
      return submit();
    }

    setStep(n);
  }

  function submit() {
    setStatus("Uploading");

    API.submitProduct(
      { ...info, thumbnail, demos, files },
      setProgress,
      (err, response) => {
        setStatus(null);
        setProgress(null);
        if (err) {
          return setError(err.response?.data?.msg || "Something went wrong.");
        }
        navigate("/products");
      }
    );
  }

  return (
    <div className="container">
      <div className="mb-2 flex">
        <h1>Add Product</h1>
        <Stepper
          step={step}
          steps={["Info", "Thumbnail", "Demos", "Files", "Review"]}
        />
      </div>

      <div className="flex wrap start center">
        <div className="block grow">
          {step === 0 && <InfoForm info={info} setInfo={setInfo} />}
          {step === 1 && (
            <ThumbnailForm
              thumbnail={thumbnail && URL.createObjectURL(thumbnail)}
              onChange={imageSelectHandler}
            />
          )}
          {step === 2 && (
            <DemoForm
              demos={demos}
              onChange={audioSelectHandler}
              setDemoName={setDemoName}
              removeDemo={removeDemo}
            />
          )}
          {step === 3 && (
            <FileForm
              files={files}
              onChange={fileSelectHandler}
              removeFile={removeFile}
            />
          )}
          {step == 4 && (
            <center>
              <p className="mb-2">Review the product preview and submit.</p>
            </center>
          )}

          <Status error={error} progress={progress} />
          <div className="button-group _add-product">
            {step > 0 && (
              <button onClick={() => switchStep(step - 1)} disabled={status}>
                Back
              </button>
            )}
            <StatusButton
              text={step < 3 ? "Next" : step === 3 ? "Review" : "Submit"}
              status={status}
              className="button"
              onClick={() => switchStep(step + 1)}
            />
          </div>
        </div>
        <ProductPreview
          info={info}
          thumbnail={thumbnail && URL.createObjectURL(thumbnail)}
          demos={demos}
          files={files}
          step={step}
        />
      </div>
    </div>
  );
}
