import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

import { ProductContext } from "../../API";
import {
  makeSelectOption,
  makeSelectOptions,
  getSelectStyles,
} from "../../API/Helpers";

export default function CreatableTypeSelect(props) {
  const { products } = useContext(ProductContext);
  const [options, setOptions] = useState([]);

  function makeOptions() {
    let types = [];
    let counter = {};
    products.forEach((product) => {
      types.push(product.type);
      counter[product.tag]
        ? counter[product.tag]++
        : (counter[product.tag] = 1);
    });
    let _options = [...new Set(types)].sort((a, b) => {
      return counter[b] - counter[a];
    });
    setOptions(makeSelectOptions(_options));
  }

  useEffect(makeOptions, [products]);

  return (
    <CreatableSelect
      options={options}
      value={makeSelectOption(props.value)}
      onChange={(value) => props.setValue(value.value.toLowerCase())}
      styles={getSelectStyles()}
      placeholder={props.placeholder}
    />
  );
}
