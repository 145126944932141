import React, { useContext, useMemo } from "react";
import CountUp from "react-countup";

import { TransactionContext } from "../../API";

export default function StatsBanner() {
  const { transactions } = useContext(TransactionContext);

  const { daily, weekly } = useMemo(() => {
    var date_today = new Date();
    date_today.setHours(0, 0, 0, 0);

    var date_sunday = new Date();
    date_sunday.setDate(date_sunday.getDate() - date_sunday.getDay());
    date_sunday.setHours(0, 0, 0, 0);

    let daily = 0,
      weekly = 0;

    transactions
      .filter((t) => t.completed)
      .forEach((transaction) => {
        var date_i = new Date(transaction.purchase_date);
        if (date_i > date_today) {
          daily += transaction.price;
        }
        if (date_i > date_sunday) {
          weekly += transaction.price;
        }
      });

    return { daily, weekly };
  });

  return (
    <div className="block flex _stats-banner">
      <center>
        <h1>
          $
          <CountUp
            start={0}
            end={daily}
            delay={0}
            duration={0.5}
            decimals={2}
            formattingFn={(value) => value.toLocaleString()}
          />
        </h1>
        <div className="stats-banner-col-subtitle">Today</div>
      </center>

      <div className="stats-banner-divider" />

      <center>
        <h1>
          $
          <CountUp
            start={0}
            end={weekly}
            delay={0}
            duration={0.5}
            decimals={2}
            formattingFn={(value) => value.toLocaleString()}
          />
        </h1>
        <div className="stats-banner-col-subtitle">Week</div>
      </center>
    </div>
  );
}
