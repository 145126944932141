import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { toPrice } from "../../API/Helpers";

import { Picker, Input, ProductSelect } from "../inputs";

import "./CouponForm.css";

export default function CouponForm(props) {
  const [discountType, setDiscountType] = useState(
    props.coupon.percentageDiscount || props.coupon.flatDiscount
      ? props.coupon.percentageDiscount
        ? "percentage"
        : "flat"
      : "percentage"
  );
  const [thresholdType, setThresholdType] = useState(
    props.coupon.threshold ? "over" : "any"
  );

  return (
    <div className="coupon-form">
      {/* Code (string) */}
      <Input
        className="coupon-form-input _locked"
        name="Code"
        value={props.coupon.code}
        setValue={(code) => props.setCoupon({ ...props.coupon, code })}
      />

      {/* Max Uses (int) */}
      <Input
        className="coupon-form-input"
        name="Max Uses (optional)"
        value={props.coupon.maxUses || ""}
        type={"number"}
        setValue={(maxUses) => props.setCoupon({ ...props.coupon, maxUses })}
      />

      <div>
        {/* Start Date */}
        <DatePicker
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          selected={props.coupon.startDate}
          onChange={(startDate) =>
            props.setCoupon({ ...props.coupon, startDate })
          }
          className="date-picker _coupon-form"
          placeholderText="Start Date"
        />
      </div>

      <div>
        {/* End Date */}
        <DatePicker
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          selected={props.coupon.endDate}
          onChange={(endDate) => props.setCoupon({ ...props.coupon, endDate })}
          className="date-picker _coupon-form"
          placeholderText="End Date"
        />
      </div>

      <ProductSelect
        value={props.coupon.samples}
        setValue={(samples) => props.setCoupon({ ...props.coupon, samples })}
        placeholder={"Valid Products (optional)"}
        isMulti={true}
        className="coupon-form-products"
      />

      <div className="coupon-form-discount">
        <Picker
          options={["percentage", "flat"]}
          option={discountType}
          setOption={(e) => {
            props.setCoupon({
              ...props.coupon,
              percentageDiscount: "",
              flatDiscount: "",
            });
            setDiscountType(e);
          }}
        />
        <div className="coupon-form-wrapper">
          {discountType === "percentage" ? "%" : "$"}
          <Input
            className="coupon-form-discount-amount"
            name={`Discount`}
            type="number"
            value={
              discountType === "percentage"
                ? props.coupon.percentageDiscount
                : props.coupon.flatDiscount
            }
            setValue={(discount) =>
              props.setCoupon({
                ...Object.assign(
                  props.coupon,
                  discountType === "percentage"
                    ? { percentageDiscount: discount }
                    : { flatDiscount: toPrice(discount) }
                ),
              })
            }
          />
        </div>
        <Picker
          options={["any", "over"]}
          option={thresholdType}
          setOption={(e) => {
            props.setCoupon({ ...props.coupon, threshold: "" });
            setThresholdType(e);
          }}
        />
        {thresholdType === "over" && (
          <div className="coupon-form-wrapper">
            $
            <Input
              className="coupon-form-threshold-amount"
              name={`Minimum Purchase`}
              type="number"
              value={props.coupon.threshold}
              setValue={(threshold) =>
                props.setCoupon({
                  ...props.coupon,
                  threshold: toPrice(threshold),
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
