import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

import { ProductContext } from "../../API";

import {
  makeSelectOptions,
  getSelectValues,
  getSelectStyles,
} from "../../API/Helpers";

export default function CreateableTagSelect(props) {
  const { products } = useContext(ProductContext);
  const [options, setOptions] = useState([]);

  function makeOptions() {
    let tags = [];
    let counter = {};
    products.forEach((product) =>
      product.tags.forEach((tag) => {
        tags.push(tag);
        counter[tag] ? counter[tag]++ : (counter[tag] = 1);
      })
    );
    let _options = [...new Set(tags)].sort((a, b) => {
      return counter[b] - counter[a];
    });
    setOptions(makeSelectOptions(_options));
  }

  useEffect(makeOptions, [products]);

  return (
    <CreatableSelect
      isMulti
      options={options}
      value={makeSelectOptions(props.value)}
      onChange={(values) => props.setValue(getSelectValues(values))}
      styles={getSelectStyles()}
      placeholder={props.placeholder}
    />
  );
}
