import React from "react";
import parse from "html-react-parser";

import { niceDate } from "../../API/Helpers";

import EmbedPlayer from "./EmbedPlayer";

import "./ProductPreview.css";

export default function ProductPreview(props) {
  return (
    <div className="product-preview block mb-2">
      {props.thumbnail && (
        <center>
          <img src={props.thumbnail} alt="" height="240" width="240" />
        </center>
      )}

      <div className="mb-2">
        {props.info?.title && <h3 className="mb-half">{props.info.title}</h3>}
        {props.info?.slug && (
          <div className="mb-half">
            <a
              href={`https://electronicsamples.com/product/${props.info.slug}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              electronicsamples.com/product/{props.info.slug}
            </a>
          </div>
        )}

        {props.info?.tags[0] && (
          <div className="mb-half flex wrap small">
            {props.info.tags.map((tag, i) => (
              <div className="product-preview-tag" key={i}>
                {tag}
              </div>
            ))}
          </div>
        )}

        <div className="flex mb-half">
          {props.info?.price && <div>$ {props.info.price}</div>}
          {props.info?.type && <div>{props.info.type}</div>}
          {props.info?.release_date && (
            <div
              className={`status-indicator _table ${
                new Date(props.info.release_date) < new Date()
                  ? "_green"
                  : "_yellow"
              }`}
            >
              {niceDate(props.info.release_date)}
            </div>
          )}
        </div>
        {props.info?.youtube_url && (
          <EmbedPlayer
            className="product-preview-video"
            url={props.info.youtube_url}
          />
        )}
        {!!props.info.description.length && (
          <div className="product-preview-description">
            {props.info.description.map((string, i) =>
              string ? <div key={i}>{parse(string)}</div> : <br key={i} />
            )}
          </div>
        )}
      </div>

      {!!props.demos.length && (
        <>
          <h4 className="mb-half">Demos</h4>
          <div className="grid _2 mb-2">
            {props.demos.map((demo, i) => (
              <>
                <b>{i + 1}</b>
                {demo.name}
              </>
            ))}
          </div>
        </>
      )}
      {!!props.files.length && (
        <>
          <h4 className="mb-half">Files</h4>
          <div className="grid">
            {props.files.map((file, i) => (
              <div key={i} className="flex mb-half">
                <b>{i + 1}</b>
                {file.name}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
