import React from "react";

import { Status } from "../misc";

import "./Inputs.css";

export default function Form({
  submit,
  className,
  horizontal,
  children,
  status,
  error,
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (status) return;
        submit && submit();
      }}
      onClick={(e) => e.stopPropagation()}
      className={`${className} vertical-flex`}
    >
      {horizontal ? <div className="flex">{children}</div> : children}
      <Status status={status} error={error} />
    </form>
  );
}
