import React from "react";

export default function FileRow(props) {
  return (
    <div className="flex mb-2">
      <b>{props.number}</b>
      <span className="grow">{props.file.name}</span>
      <button onClick={props.removeFile}>
        <b>Delete</b>
      </button>
    </div>
  );
}
