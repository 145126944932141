import React, { useContext, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

import { ProductContext } from "../API";

import { Input, Picker } from "../components/inputs";
import { Status } from "../components/misc";
import { ProductRow } from "../components/products";

import "./Products.css";

export default function Products() {
  const { products, productsStatus } = useContext(ProductContext);

  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [length, setLength] = useState(25);
  const [scroll, setScroll] = useState(null);

  const filteredProducts = useMemo(() => {
    if (!products.length) return [];

    let now = new Date();
    return products.filter((product) => {
      if (status !== "all") {
        let releaseDate = new Date(product.release_date);
        if (status === "released" && now < releaseDate) {
          return false;
        } else if (status === "pre-release" && now > releaseDate) {
          return false;
        }
      }

      //search filter
      if (search) {
        if (
          !product.tags.some((tag) =>
            tag.toLowerCase().includes(search.toLowerCase())
          ) &&
          !product.title.toLowerCase().includes(search.toLowerCase())
        )
          return false;
      }

      return true;
    });
  }, [search, status, products]);

  useEffect(() => {
    window.addEventListener("scroll", () =>
      setScroll(
        window.innerHeight + Math.ceil(window.pageYOffset) >=
          document.body.offsetHeight
      )
    );
  }, []);

  useEffect(() => {
    if (scroll) showMore();
  }, [scroll]);

  function showMore() {
    setLength((prevLength) =>
      prevLength + 25 < filteredProducts.length
        ? prevLength + 25
        : filteredProducts.length
    );
  }

  function select(id) {
    selected === id ? setSelected(null) : setSelected(id);
  }

  return (
    <div className="container _products">
      <div className="flex mb-2">
        <h1>Products</h1>
        <Link className="flex small hover" to="/products/add">
          <div className="icon plus" />
          Add Product
        </Link>
      </div>
      <div className="block">
        <div className="flex wrap mb-2">
          <Input
            className="grow"
            name={`Search [${filteredProducts.length}]`}
            type="search"
            value={search}
            setValue={setSearch}
          />
          <Picker
            options={["all", "released", "pre-release"]}
            option={status}
            setOption={setStatus}
          />
        </div>
        {productsStatus ? (
          <center>
            <Status status={productsStatus} />
          </center>
        ) : !filteredProducts.length ? (
          <center>No products found.</center>
        ) : (
          <>
            <div className="grid _products mb-1">
              <div className="grid-row _header">
                <h4>Release</h4>
                <h4>Product</h4>
                <h4>Type</h4>
              </div>
              {filteredProducts.slice(0, length).map((product) => (
                <ProductRow
                  key={product._id}
                  product={product}
                  open={product._id === selected}
                  onClick={() => select(product._id)}
                />
              ))}
            </div>
            {length < filteredProducts.length && (
              <>
                <center>
                  <button className="mb-1" onClick={showMore}>
                    <b>Show More</b>
                  </button>
                </center>

                <div className="grid-length">
                  {length}/{filteredProducts.length}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
