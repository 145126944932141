import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { PopUpContext } from "../../API";
import { niceDate } from "../../API/Helpers";

import { DateRange } from "../inputs";
import { ProductDetailsGraph } from "../stats";
import DeleteProduct from "./DeleteProduct";
import SendProducts from "./SendProducts";

export default function ProductRow({ product, open, onClick }) {
  const { setPopUp } = useContext(PopUpContext);

  function deleteProduct(product) {
    setPopUp({
      title: "Delete Product",
      component: () => <DeleteProduct product={product} />,
    });
  }

  function sendProduct(product) {
    setPopUp({
      title: "Send Products",
      component: () => <SendProducts products={[product._id]} />,
    });
  }

  return (
    <button onClick={onClick} className="grid-row">
      <span
        className={`status-indicator ${
          new Date(product.release_date) < new Date() ? "_green" : "_yellow"
        }`}
      >
        {niceDate(product.release_date)}
      </span>
      <span className="line-clamp _1">{product.title}</span>
      <span>{product.type}</span>

      <Link to={`/products/edit/${product.slug}`} className="grid-button">
        Edit
      </Link>
      <button
        onClick={(e) => {
          e.stopPropagation();
          deleteProduct(product);
        }}
        className="grid-button"
      >
        Delete
      </button>
      {open && (
        <div className="grid-expanded">
          <ProductDetailsGraph product={product} className="mb-2" />
          <button
            className="button"
            onClick={(e) => {
              e.stopPropagation();
              sendProduct(product);
            }}
          >
            Send Product
          </button>
        </div>
      )}
    </button>
  );
}
