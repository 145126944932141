import React, { useRef } from "react";

import { FileRow } from ".";

import "./Forms.css";

export default function DemoForm(props) {
  const fileInput = useRef();

  return (
    <div className="file-form mb-2">
      <div className="file-form-files">
        {props.files.map((file, i) => (
          <FileRow
            key={i}
            number={i + 1}
            file={file}
            removeFile={() => props.removeFile(i)}
          />
        ))}
      </div>

      {props.onChange && (
        <button onClick={() => fileInput.current.click()} className="button">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={props.onChange}
            ref={fileInput}
          />
          Upload File
        </button>
      )}
    </div>
  );
}
