import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Header, Nav } from "./components/navigation";
import { PopUpRender, LogIn } from "./components/popup";
import { NotificationManager } from "./components/notifications";
import {
  Stats,
  Products,
  AddProduct,
  EditProduct,
  Transactions,
  Coupons,
  AddCoupon,
  EditCoupon,
  Mail,
} from "./pages";

import "./styles/index.css";
import "./styles/icons.css";
import "./styles/grid.css";

export default function App() {
  return (
    <>
      <Nav />
      <div className="page-wrapper grow vertical-flex">
        <Header />
        <div id="page" className="grow">
          <Routes>
            {/* Stats */}
            <Route path="" element={<Stats />} />

            {/* Product */}
            <Route path="products" element={<Products />} />
            <Route path="products/add" element={<AddProduct />} />
            <Route
              path="products/edit/:productSlug"
              element={<EditProduct />}
            />

            {/* Transactions */}
            <Route path="transactions" element={<Transactions />} />

            {/* Coupons */}
            <Route path="coupons" element={<Coupons />} />
            <Route path="coupons/add" element={<AddCoupon />} />
            <Route path="coupons/edit/:couponId" element={<EditCoupon />} />

            {/* Users */}
            <Route path="mail" element={<Mail />} />

            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </div>

      <PopUpRender />
      <LogIn />
      <NotificationManager />
    </>
  );
}

function NoMatch() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
}
