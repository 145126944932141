import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { APIContext, CouponContext } from "../API";

import { CouponForm } from "../components/coupons";
import { Status, StatusButton } from "../components/misc";

export default function EditProductPage() {
  const { couponId } = useParams();
  const { coupons } = useContext(CouponContext);
  const coupon = useMemo(
    () => coupons?.find((c) => c._id == couponId),
    [couponId, coupons]
  );

  return (
    <div className="container">
      <h1 className="mb-2">Edit Coupon</h1>
      {!coupon ? <Status status="Loading" /> : <EditCoupon coupon={coupon} />}
    </div>
  );
}

function EditCoupon(props) {
  const API = useContext(APIContext);

  const [coupon, setCoupon] = useState({
    ...props.coupon,
    startDate: new Date(props.coupon.startDate),
    endDate: new Date(props.coupon.endDate),
  });
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  function submit() {
    setError(null);
    if (status) {
      return;
    }

    if (!coupon.code) {
      return setError("Missing code");
    }

    if (!coupon.startDate) {
      return setError("Missing start date");
    }

    if (!coupon.endDate) {
      return setError("Missing end date");
    }

    if (!coupon.flatDiscount && !coupon.percentageDiscount) {
      return setError("Missing discount");
    }

    setStatus("sending");

    API.editCoupon(coupon, (err, response) => {
      setStatus(null);
      if (err) {
        return setError(err.response?.data?.msg || "Something went wrong.");
      }
      props.close && props.close();
    });
  }

  return (
    <div className="block">
      <CouponForm coupon={coupon} setCoupon={setCoupon} />
      <Status error={error} />
      <StatusButton
        text="Submit"
        className="button"
        status={status}
        onClick={submit}
      />
    </div>
  );
}
