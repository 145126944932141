import { NavLink } from "react-router-dom";

import "./Nav.css";

export default function AdminNav(props) {
  return (
    <nav>
      <NavLink to="/" className={`admin-nav-icon _stats`} />
      <NavLink to="/products" className={`admin-nav-icon _products`} />
      <NavLink to="/transactions" className={`admin-nav-icon _transactions`} />
      <NavLink to="/coupons" className={`admin-nav-icon _coupons`} />
      <NavLink to="/mail" className={`admin-nav-icon _mail`} />
    </nav>
  );
}
