import React, { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import axios from "axios";

import {
  UserContext,
  PopUpContext,
  ProductContext,
  TransactionContext,
  SubscriptionContext,
  CouponContext,
  NotificationContext,
} from "./";

const APIContext = createContext();

const Axios = axios.create({
  baseURL:
    window.location.hostname === "admin.electronicsamples.com"
      ? "https://api.electronicsamples.com"
      : "https://test.electronicsamples.com",
});

function APIProvider(props) {
  const prod = window.location.hostname === "admin.electronicsamples.com";

  const { token, setToken, setUser } = useContext(UserContext);
  const { setPopUp } = useContext(PopUpContext);
  const {
    setTransactions,
    setTransactionsStatus,
    setTransactionsProgress,
    startDate,
    stopDate,
  } = useContext(TransactionContext);
  const { subscriptions, setSubscriptions } = useContext(SubscriptionContext);
  const { products, setProducts, setProductsStatus } =
    useContext(ProductContext);
  const { setCoupons } = useContext(CouponContext);
  const { notifications, setNotifications } = useContext(NotificationContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      getUser();
      getProducts();
      getCoupons();
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.removeItem("token");
      navigate("/");
    }
  }, [token]);

  useEffect(() => {
    if (token) { getTransactions(); getSubscriptions(); }
  }, [token, startDate, stopDate]);

  function logIn(email, password, cb) {
    const body = {
      email,
      password,
    };
    Axios.post("/auth/login", body)
      .then((response) => {
        setToken(response.data.token);
        setPopUp(null);
        cb && cb(null, response);
      })
      .catch((err) => cb && cb(err));
  }

  function getUser() {
    const headers = {
      Authorization: "Bearer " + token,
    };

    return Axios.get("/auth/user", { headers }).then((response) => {
      setUser(response.data);
    });
  }

  //get products and transactions on initialize
  function fetchData() {
    if (!token) return;
    if (notifications.some((n) => n.type === "fetch")) return;

    const _notifications = Array.from(notifications);
    _notifications.push({
      _id: nanoid(),
      type: "fetch",
      message: "Fetching data",
    });

    setNotifications(_notifications);
  }

  function getTransactions() {
    const headers = {
      Authorization: "Bearer " + token,
    };

    setTransactionsStatus("Loading");

    return Axios.get(
      `/samples/admin/transactions?startDate=${startDate.valueOf()}&stopDate=${stopDate.valueOf()}`,
      {
        headers,
        onDownloadProgress: (e) =>
          setTransactionsProgress(Math.round((e.loaded * 100) / e.total)),
      }
    )
      .then((response) => {
        setTransactions(response.data.transactions);
        setTransactionsStatus();
        setTransactionsProgress();
        sessionStorage.setItem("lastFetch", Date.now());
      })
      .catch((err) => {
        setTransactionsStatus();
        setTransactionsProgress();
        if (Axios.isCancel(err)) {
          return;
        }
        setToken(null);
        setPopUp({
          title: "Something went wrong",
          message:
            "Unable to connect to server.  Please log in again or contact support.",
        });
      });
  }

  function getSubscriptions() {
    const headers = {
      Authorization: "Bearer " + token,
    };

    return Axios.get(
      `/payments/subscription/statistics`,
      {
        headers,
      }
    )
      .then((response) => {
        setSubscriptions(response.data.plans);
      })
      .catch((err) => {
        setToken(null);
        setPopUp({
          title: "Something went wrong",
          message:
            "Unable to connect to server.  Please log in again or contact support.",
        });
      });
  }

  function getProducts() {
    const headers = {
      Authorization: "Bearer " + token,
    };

    setProductsStatus("Loading");

    return Axios.get(`/samples/admin`, {
      headers,
    })
      .then((response) => {
        setProducts(response.data.samples);
        setProductsStatus();
      })
      .catch((err) => {
        setProductsStatus();

        if (Axios.isCancel(err)) {
          return;
        }
        setToken(null);
        setPopUp({
          title: "Something went wrong",
          message:
            "Unable to connect to server.  Please log in again or contact support.",
        });
      });
  }

  //get a product by ID - do not use in loop
  function getProductsById(ids) {
    return products.filter((product) => {
      return ids.includes(product._id);
    });
  }

  //submit new product to store
  function submitProduct(product, setProgress, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = new FormData();
    body.append("title", product.title);
    body.append("type", product.type);
    body.append("price", product.price);
    body.append("slug", product.slug);
    body.append("release_date", product.release_date);
    body.append("youtube_url", product.youtube_url);
    body.append("tags", JSON.stringify(product.tags));
    body.append("description", JSON.stringify(product.description));
    body.append("thumbnail", product.thumbnail);

    for (let demo of Object.values(product.demos)) {
      body.append("demos_" + demo.name, demo.audio);
    }

    for (let file of product.files) {
      body.append("products_" + file.name, file);
    }

    Axios.post("/samples", body, {
      headers,
      onUploadProgress: (e) =>
        setProgress(Math.round((e.loaded * 100) / e.total)),
    })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Submit Product",
          message: `"${product.title}" has been added to your store.`,
          buttonText: "return",
        });
        cb && cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //editProduct
  function editProduct(product, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = new FormData();
    body.append("_id", product._id);
    body.append("title", product.title);
    body.append("type", product.type);
    body.append("price", product.price);
    body.append("oldPrice", product.oldPrice);
    body.append("slug", product.slug);
    body.append("release_date", product.release_date);
    body.append("youtube_url", product.youtube_url);
    body.append("tags", JSON.stringify(product.tags));
    body.append("description", JSON.stringify(product.description));

    Axios.put("/samples", body, { headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Edit Product",
          message: `Success.  The details of "${product.title}" have been updated.`,
          buttonText: "return",
        });
        cb && cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //deleteProduct
  function deleteProduct(product, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = {
      _id: product._id,
    };
    Axios.delete("/samples", { data: body, headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Delete Product",
          message: `"${product.title}" has been removed from your store.`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //editProductThumbnail
  function changeThumbnail(product, thumbnail, setProgress, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = new FormData();
    body.append("_id", product._id);
    body.append("thumbnail", thumbnail);

    Axios.put("/samples/thumbnails", body, {
      headers,
      onUploadProgress: (e) =>
        setProgress(Math.round((e.loaded * 100) / e.total)),
    })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Change Thumbnail",
          message: `Success.  The thumbnail for "${product.title}" has been changed.`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //add a product demo
  function addDemo(product, demo, setProgress, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = new FormData();
    body.append("_id", product._id);
    body.append("name", demo.name);
    body.append("demo", demo.audio);

    Axios.post("/samples/demos", body, {
      headers,
      onUploadProgress: (e) =>
        setProgress(Math.round((e.loaded * 100) / e.total)),
    })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Add Product Demo",
          message: `Success.  "${demo.name}" has been added to "${product.title}".`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //delete a product demo
  function deleteDemo(product, name, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = {
      _id: product._id,
      name,
    };

    Axios.delete("/samples/demos", { data: body, headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Delete Product Demo",
          message: `Success.  "${name}" was removed from "${product.title}".`,
          buttonText: "return",
        });
        cb && cb(null, response);
      })
      .catch((err) => cb && cb(err));
  }

  //add files to an existing product
  function addFiles(product, file, setProgress, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = new FormData();
    body.append("_id", product._id);
    body.append("name", file.name);
    body.append("product", file);

    Axios.post("/samples/products", body, {
      headers,
      onUploadProgress: (e) =>
        setProgress(Math.round((e.loaded * 100) / e.total)),
    })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Add Product Files",
          message: `Success.  "${file.name}" has been added to "${product.title}".`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  //delete product files
  function deleteFiles(product, name, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = {
      _id: product._id,
      name,
    };

    Axios.delete("/samples/products", { data: body, headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Delete Product Files",
          message: `Success.  "${name}" was removed from "${product.title}".`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  function sendProducts(name, email, samples, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = { name, email, samples };

    Axios.post("/cart/gift", body, { headers })
      .then((response) => {
        setPopUp({
          title: "Send Products",
          message: `Success.  ${samples.length} ${samples.length > 1 ? "products have" : "product has"
            } been sent to ${name} [${email}].`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .then((err) => {
        cb(err);
      });
  }

  function resendTransaction(transaction, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    const body = { transactionId: transaction._id };

    Axios.post("/cart/deliver", body, { headers })
      .then((response) => {
        setPopUp({
          title: "Resend Transaction",
          message: `Success.  "${transaction._id}" has been resent to ${transaction.name} [${transaction.customer_email}].`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  function getCoupons() {
    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.get("/coupons", { headers })
      .then((response) => {
        setCoupons(response.data);
      })
      .catch((err) => {
        setPopUp({
          title: "Something went wrong",
          message:
            "Unable to connect to server.  Please try again or contact support.",
        });
      });
  }

  function addCoupon(coupon, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.put("/coupons", coupon, { headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Add Coupon",
          message: `Success.  "${coupon.code}" has been uploaded.`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  function editCoupon(coupon, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.put("/coupons", coupon, { headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Edit Coupon",
          message: `Success.  The details of "${coupon.code}" have been updated.`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  function deleteCoupon(coupon, cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    Axios.delete("/coupons/" + coupon._id, { headers })
      .then((response) => {
        fetchData();
        setPopUp({
          title: "Delete Coupon",
          message: `"${coupon.code}" has been removed from your store.`,
          buttonText: "return",
        });
        cb(null, response);
      })
      .catch((err) => {
        cb(err);
      });
  }

  function getEmails(cb) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    return Axios.get("/samples/admin/emails", { headers })
      .then((response) => cb(null, response))
      .catch((err) => {
        cb(err);
      });
  }

  function paypalLogIn() {
    window.open(
      `https://www${prod ? "" : ".sandbox"
      }.paypal.com/connect?flowEntry=static&client_id=${prod
        ? "AREHnIUyTFaUnj_CBMQrTfr4fVIgXaMRMVHxEzoIZzURAyu_a8wftPs28uUlZNLCS0fRFpJZlQMOD1mm"
        : "ATPH2lXlvueppNay_76435_NK9XzMxbOYrqJmZYujjY48PZD0M8ZhMjaxskiPYz0MjRc8QTRfwh09dwB"
      }&scope=openid&redirect_uri=${encodeURIComponent(
        window.location.origin + "/payouts"
      )}`,
      "_self"
    );
  }

  function paypalPayout(amount) {
    const headers = {
      Authorization: "Bearer " + token,
    };

    return Axios.post("/payments/payout", { amount }, { headers });
  }

  return (
    <APIContext.Provider
      value={{
        logIn,
        // fetchData,
        // fetchDataHelper,
        getTransactions,
        getProducts,
        getProductsById,
        submitProduct,
        editProduct,
        changeThumbnail,
        addDemo,
        deleteDemo,
        addFiles,
        deleteFiles,
        deleteProduct,
        sendProducts,
        resendTransaction,
        getCoupons,
        addCoupon,
        editCoupon,
        deleteCoupon,
        getEmails,
        paypalLogIn,
        paypalPayout,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
}

export { APIContext, APIProvider };
