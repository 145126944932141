import React from "react";

export default function ScrollLock() {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `:root {overflow: hidden}`,
      }}
    />
  );
}
