import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  parseDescription,
  makeDescription,
  generateTags,
  generateType,
  generateSlug,
  toPrice,
} from "../../API/Helpers";

import {
  Form,
  Input,
  CreatableTypeSelect,
  CreatableTagSelect,
} from "../inputs";

import "./Forms.css";

export default function InfoForm({ info, setInfo }) {
  function handleTitleBlur(title) {
    setInfo({
      ...info,
      type: info.type || generateType(title),
      tags: info.tags.length !== 0 ? info.tags : generateTags(title),
      slug: info.slug || generateSlug(title),
    });
  }

  return (
    <Form className="info-form mb-2">
      <div className="flex">
        <input type="checkbox" value={info.inactive} />
        <label>Draft</label>
      </div>

      {/* Title (string) */}
      <Input
        label
        name="Title"
        value={info.title}
        setValue={(title) => setInfo({ ...info, title })}
        onBlur={() => info.title && handleTitleBlur(info.title)}
      />
      {/* Slug (string) */}
      <div>
        <label className="mb-half" htmlFor="URL">
          URL
        </label>
        <div className="flex no-gap">
          <span className="info-form-url-prefix">
            electronicsamples.com/products/
          </span>
          <Input
            className="grow"
            name="URL"
            value={info.slug}
            setValue={(slug) => setInfo({ ...info, slug })}
          />
        </div>
      </div>
      <div className="flex">
        {/* Price (float) */}
        <Input
          label
          className="grow"
          name="Current Price"
          type="number"
          value={info.price}
          setValue={(price) =>
            setInfo({
              ...info,
              price: toPrice(price),
            })
          }
        />

        {/* Price (float) */}
        <Input
          label
          className="grow"
          name="Old Price"
          placeholder="Old Price [optional]"
          type="number"
          value={info.oldPrice}
          setValue={(price) =>
            setInfo({
              ...info,
              oldPrice: toPrice(price),
            })
          }
        />
      </div>
      {/*Type Select*/}
      <div>
        <label className="mb-half">Product Type</label>
        <CreatableTypeSelect
          placeholder="Type"
          value={info.type}
          setValue={(type) => setInfo({ ...info, type })}
        />
      </div>

      {/* Release Date */}
      <div className="grow">
        <label className="mb-half">Release Date</label>
        <DatePicker
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          selected={info.release_date}
          onChange={(release_date) => {
            setInfo({ ...info, release_date });
          }}
          className="date-picker"
          placeholderText="Release Date"
        />
      </div>

      {/* Youtube Link */}
      <Input
        label
        name="Youtube URL"
        value={info.youtube_url}
        setValue={(youtube_url) => setInfo({ ...info, youtube_url })}
      />
      {/* Tags (array of strings) */}
      <div className="info-form-tags-wrapper">
        <label className="mb-half">Tags</label>
        <CreatableTagSelect
          placeholder="Tags"
          value={info.tags}
          setValue={(tags) => setInfo({ ...info, tags })}
        />
      </div>
      {/* Description */}
      <div>
        <label htmlFor="description" className="mb-half">
          Description
        </label>
        <textarea
          spellCheck="false"
          placeholder="Description [Optional]"
          id="description"
          rows="10"
          value={makeDescription(info.description)}
          onChange={(e) =>
            setInfo({
              ...info,
              description: parseDescription(e.target.value),
            })
          }
        />
      </div>
    </Form>
  );
}
