import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { PopUpContext } from "../../API";
import { niceDate } from "../../API/Helpers";

import DeleteCoupon from "./DeleteCoupon";

export default function CouponRow(props) {
  const { setPopUp } = useContext(PopUpContext);

  const status = useMemo(getStatus, [props.coupon]);

  function getStatus() {
    const now = new Date();
    if (now > new Date(props.coupon.endDate)) {
      return "expired";
    } else if (now < new Date(props.coupon.startDate)) {
      return "upcoming";
    } else {
      return "active";
    }
  }

  function deleteCoupon() {
    setPopUp({
      title: "Delete Coupon",
      component: () => <DeleteCoupon coupon={props.coupon} />,
      close: () => setPopUp(null),
    });
  }

  return (
    <div className="grid-row">
      <span
        className={`status-indicator ${
          { active: "_green", upcoming: "_yellow", expired: "_red" }[status]
        }`}
      >
        {status}
      </span>
      <span>{props.coupon.code}</span>
      <span>{niceDate(props.coupon.startDate)}</span>
      <span>{niceDate(props.coupon.endDate)}</span>
      <Link to={`/coupons/edit/${props.coupon._id}`} className="grid-button">
        Edit
      </Link>
      <button className="grid-button" onClick={deleteCoupon}>
        Delete
      </button>
    </div>
  );
}
