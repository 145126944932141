import React, { useContext, useMemo } from "react";
import CountUp from "react-countup";

import { SubscriptionContext } from "../../API";

export default function SubsBanner() {
  const { subscriptions } = useContext(SubscriptionContext);
  console.log('sub.plan', subscriptions?.[0])
  const totalMonthlyRevenue = subscriptions?.length ? subscriptions.filter((sub) => sub.plan.billingFrequency === 1).reduce((acc, sub) => acc + parseFloat(sub.plan.price) * sub.count, 0) : 0;
  const totalAnnualRevenue = subscriptions?.length ? subscriptions.filter((sub) => sub.plan.billingFrequency === 12).reduce((acc, sub) => acc + parseFloat(sub.plan.price) * sub.count, 0) : 0;
  return (
    <div className="block flex _stats-banner start">
      {subscriptions.map((sub) => {
        return (
          <div key={sub.id} className="card _stats-card">
            <h2 className="mb-2">Plan {sub.plan.name}</h2>
            <p className="mb-2">
              <CountUp end={sub.count} duration={2} />
              <br /><small>subscribers</small>
            </p>
            <p className="mb-2">
              <CountUp end={sub.plan.price} duration={2} prefix="$" decimals={2} />
              <br /><small>plan price</small>
            </p>
            <p className="mb-2">
              <CountUp end={parseFloat(sub.plan.price) * sub.count} duration={2} prefix="$" decimals={2} />
              <br /><small>plan revenue</small>
            </p>
          </div>
        );
      })}
      <div className="card _stats-card">
        <h2 className="mb-2">Subscription Stats</h2>
        <p className="mb-2">
          ${
            totalMonthlyRevenue
          }
          <br /><small>Monthly Subscription Revenue</small>
        </p>
        <p className="mb-2">
          ${
            totalAnnualRevenue
          }
          <br /><small>Annual Subscription Revenue</small>
        </p>
      </div>
      <div className="card _stats-card">
      </div>
    </div>
  );
}
