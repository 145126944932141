import React, { useContext, useState } from "react";

import { APIContext, PopUpContext } from "../../API";
import { testEmail } from "../../API/Helpers";

import { Form, Input, ProductSelect } from "../inputs";
import { Status, StatusButton, CartRow } from "../misc";

export default function SendProduct(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [products, setProducts] = useState(props.products || null);
  const [name, setName] = useState(props.name || "");
  const [email, setEmail] = useState(props.email || "");
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  function submit() {
    if (status) return;

    setError(null);

    if (!products.length) {
      return setError("Please select at least one product.");
    }

    if (!name) {
      return setError("Please enter a name.");
    }

    if (!email) {
      return setError("Please enter an email address.");
    }

    if (!testEmail(email)) {
      return setError("Email address format invalid.");
    }

    setStatus("Sending");
    API.sendProducts(name, email, products, (err, response) => {
      setStatus(null);
      if (err) {
        return setError(
          err.response?.data?.msg ||
            `Something went wrong.  "${props.product.title}" was not sent to ${name}.`
        );
      }
    });
  }

  return (
    <Form error={error}>
      {products?.map((product, i) => (
        <CartRow
          product={product}
          remove={() =>
            setProducts((prevProducts) =>
              prevProducts.filter((p) => p !== product)
            )
          }
        />
      ))}
      <ProductSelect
        placeholder={"Select products..."}
        value={products}
        setValue={setProducts}
        isMulti={true}
        className="product-select _send-products"
      />
      <Input
        name="Name"
        className="_send-products"
        value={name}
        setValue={setName}
        required
      />
      <Input
        name="Email"
        className="_send-products"
        value={email}
        setValue={setEmail}
        required
      />
      <div className="button-group">
        <StatusButton
          className={`button _pop-up ${status && "_disabled"}`}
          style={{ order: 1 }}
          status={status}
          onClick={submit}
          text="Send"
          type="submit"
        />
        <button onClick={() => setPopUp(null)}>Cancel</button>
      </div>
    </Form>
  );
}
