import React from "react";

import "./Header.css";

export default function Header() {
  return (
    <header>
      <img
        width="128"
        height="128"
        id="header-logo"
        src="/assets/images/logo.png"
        alt="ES Admin Logo"
      />
    </header>
  );
}
