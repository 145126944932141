import React, { useState, useRef } from "react";

export default function AudioPlayer(props) {
  const [on, setOn] = useState(false);

  const audio = useRef();

  return (
    <div className="audio-player" onClick={() => setOn((o) => !o)}>
      {on && (
        <audio
          autoPlay
          ref={audio}
          src={
            typeof props.audio === "object"
              ? URL.createObjectURL(props.audio)
              : props.audio
          }
          type="audio/mpeg"
        />
      )}
      <div>
        <div className={`audio-player-icon ${on ? "_on" : "_off"}`} />
      </div>
      <div className="audio-player-title">{props.index}</div>
    </div>
  );
}
