import React, { useRef } from "react";

import DemoRow from "./DemoRow";

import "./Forms.css";

export default function DemoForm(props) {
  const audioInput = useRef();

  return (
    <div className="demo-form mb-2">
      <div className="mb-1">
        {props.demos.map((demo, i) => (
          <DemoRow
            key={i}
            index={i + 1}
            demo={demo}
            setName={
              props.setDemoName ? (name) => props.setDemoName(i, name) : null
            }
            removeDemo={() => props.removeDemo(i)}
          />
        ))}
      </div>

      {props.onChange && (
        <button onClick={() => audioInput.current.click()} className="button">
          <input
            type="file"
            accept="audio/*"
            style={{ display: "none" }}
            onChange={props.onChange}
            ref={audioInput}
          />
          Upload Demo
        </button>
      )}
    </div>
  );
}
