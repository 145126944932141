import React, { useContext } from "react";

import { PopUpContext } from "../../API";
import { niceDate } from "../../API/Helpers";

import { CartRow } from "../misc";
import ResendTransaction from "./ResendTransaction";

export default function TransactionRow({
  transaction,
  setSearch,
  setSelected,
  selected,
}) {
  const { setPopUp } = useContext(PopUpContext);

  function resend() {
    setPopUp({
      title: "Resend Transaction",
      component: () => <ResendTransaction transaction={transaction} />,
    });
  }

  return (
    <button
      className="grid-row"
      onClick={() => transaction.completed && setSelected(transaction._id)}
    >
      <span
        className={`status-indicator ${
          transaction.completed ? "_green" : "_red"
        }`}
      >
        {transaction.completed ? "Complete" : "Incomplete"}
      </span>
      <span>${transaction.price}</span>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setSearch(transaction.name);
        }}
        className="grid-button"
      >
        {transaction.name}
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setSearch(transaction.customer_email);
        }}
        className="grid-button"
      >
        {transaction.customer_email}
      </button>
      <span>{niceDate(transaction.purchase_date)}</span>
      {selected === transaction._id && (
        <div className="grid-expanded">
          {transaction.urls.map((sample, i) => (
            <CartRow
              key={sample.sample_id}
              index={i + 1}
              product={sample.sample_id}
            />
          ))}
          <br />
          <div className="divider _light mb-1" />
          <div className="flex">
            <span className="grow">Total</span>${transaction.price}
          </div>
          <button
            className="button"
            onClick={(e) => {
              e.stopPropagation();
              resend();
            }}
          >
            Resend Transaction
          </button>
        </div>
      )}
    </button>
  );
}
