import React, { useContext, useMemo } from "react";
import Plot from "react-plotly.js";

import { TransactionContext } from "../../API";
import { createDateRange, ISODate } from "../../API/Helpers";

import { DateRange } from "../inputs";

const data = [
  {
    type: "scatter",
    mode: "lines+markers",
    marker: { color: "black" },
  },
];
const layout = {
  font: {
    family: "Bebas Neue",
  },
  margin: {
    t: 30,
    b: 30,
    l: 30,
    r: 30,
    pad: 30,
  },
  yaxis: {
    automargin: true,
    fixedrange: true,
    tickformat: "$",
    rangemode: "tozero",
  },
  xaxis: {
    showgrid: false,
    automargin: true,
    fixedrange: true,
    tickformat: "%B %d \n %Y",
  },
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
};

export default function ProductDetailsGraph(props) {
  const { transactions, startDate, stopDate } = useContext(TransactionContext);

  const { total, x, y } = useMemo(() => {
    let total = 0;
    let data = Object.fromEntries(
      createDateRange(startDate, stopDate).map((date) => [ISODate(date), 0])
    );

    transactions
      .filter((t) => t.completed)
      .forEach((transaction) => {
        transaction.urls.forEach((url) => {
          if (url.sample_id === props.product._id) {
            let date = ISODate(new Date(transaction.purchase_date));
            if (date in data) {
              data[date] += transaction.price;
            } else {
              data[date] = transaction.price;
            }
            total += transaction.price;
          }
        });
      });

    return { total, x: Object.keys(data), y: Object.values(data) };
  }, [transactions]);

  return (
    <div className={props.className}>
      {total ? (
        <Plot
          data={[
            {
              ...data[0],
              x,
              y,
            },
          ]}
          layout={layout}
          className="stats-graph"
          config={{ displayModeBar: false, responsive: true }}
        />
      ) : (
        <div className="stats-no-sales">No sales.</div>
      )}
      <div className="flex wrap" onClick={(e) => e.stopPropagation()}>
        <DateRange />
        <div className="flex small">
          Total:
          <b>${total}</b>
        </div>
      </div>
    </div>
  );
}
