import React, { useContext, useState, useMemo, useEffect } from "react";

import { APIContext, PopUpContext } from "../API";

import { SendProducts } from "../components/products";
import { Input } from "../components/inputs";
import { GetMailingList } from "../components/mail";
import { Status } from "../components/misc";

import "./Mail.css";

export default function Mail() {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [length, setLength] = useState(50);
  const [scroll, setScroll] = useState(null);
  const [status, setStatus] = useState();

  const filteredUsers = useMemo(() => {
    if (!users.length) return [];

    return users.filter((user) => {
      if (
        search &&
        !user.name?.toLowerCase().includes(search.toLowerCase()) &&
        !user.email?.toLowerCase().includes(search.toLowerCase())
      ) {
        return false;
      }

      return true;
    });
  }, [search, users]);

  useEffect(() => {
    setStatus("Loading");

    API.getEmails((err, response) => {
      setUsers(response.data.users);
      setStatus();
    }).catch(() => {
      setStatus();
    });

    window.addEventListener("scroll", () =>
      setScroll(
        window.innerHeight + Math.ceil(window.pageYOffset) >=
          document.body.offsetHeight
      )
    );
  }, []);

  useEffect(() => {
    if (scroll) showMore();
  }, [scroll]);

  function showMore() {
    setLength((prevLength) =>
      prevLength + 25 < filteredUsers.length
        ? prevLength + 25
        : filteredUsers.length
    );
  }

  function sendProducts(user) {
    setPopUp({
      title: "Send Products",
      component: () => (
        <SendProducts name={user.name.split("@")[0]} email={user.email} />
      ),
    });
  }

  return (
    <div className="container _mail">
      <h1 className="mb-2">Mail</h1>

      <div className="block">
        <div className="flex wrap mb-2">
          <Input
            className="grow"
            name={`Search [${filteredUsers.length}]`}
            type="search"
            value={search}
            setValue={setSearch}
          />
          {!!filteredUsers.length && <GetMailingList users={filteredUsers} />}
        </div>

        {status ? (
          <center>
            <Status status={status} />
          </center>
        ) : (
          <div className="grid _mail mb-1">
            <div className="grid-row _header">
              <h4>Name</h4>
              <h4>Email</h4>
            </div>
            {filteredUsers.slice(0, length).map((user, index) => (
              <div className="grid-row" key={index}>
                <span>{user.name || "-"}</span>
                <span>{user.email || "-"}</span>
                <button onClick={() => sendProducts(user)}>
                  Send Products
                </button>
              </div>
            ))}
          </div>
        )}
        {length < filteredUsers.length && (
          <>
            <center>
              <button className="mb-1" onClick={showMore}>
                Show More
              </button>
            </center>
            <div className="grid-length">
              {length}/{filteredUsers.length}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
