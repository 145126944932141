import React, { useContext } from "react";

import { UserContext } from "../API";

import {
  SubsBanner,
  StatsBanner,
  DailyRevenue,
  ProductComparison,
} from "../components/stats";

import "./Stats.css";

export default function Stats() {
  const { user } = useContext(UserContext);

  return (
    <div className="container _stats">
      <h1 className="mb-2">Welcome, {user?.name}</h1>

      <StatsBanner />
      <SubsBanner />
      <DailyRevenue />
      <ProductComparison />
    </div>
  );
}
