import React, { createContext, useState } from "react";
import { APIProvider } from "./API";

const UserContext = createContext();
const PopUpContext = createContext();
const ProductContext = createContext();
const SubscriptionContext = createContext();
const TransactionContext = createContext();
const CouponContext = createContext();
const NotificationContext = createContext();

function ContextStore(props) {
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [user, setUser] = useState();
  const [popUp, setPopUp] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsStatus, setProductsStatus] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [notifications, setNotifications] = useState([
    // { message: "Updating" }
  ]);

  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsStatus, setTransactionsStatus] = useState();
  const [transactionsProgress, setTransactionsProgress] = useState();
  const stop = new Date();
  stop.setHours(23, 59, 59, 999);
  const start = new Date();
  start.setDate(start.getDate() - 7);
  start.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(start);
  const [stopDate, setStopDate] = useState(stop);

  return (
    <UserContext.Provider value={{ token, setToken, user, setUser }}>
      <PopUpContext.Provider value={{ popUp, setPopUp }}>
        <ProductContext.Provider
          value={{ products, setProducts, productsStatus, setProductsStatus }}
        >
          <TransactionContext.Provider
            value={{
              transactions,
              setTransactions,
              transactionsStatus,
              setTransactionsStatus,
              transactionsProgress,
              setTransactionsProgress,
              startDate,
              setStartDate,
              stopDate,
              setStopDate,
            }}
          >
            <SubscriptionContext.Provider value={{ subscriptions, setSubscriptions }}>
              <CouponContext.Provider value={{ coupons, setCoupons }}>
                <NotificationContext.Provider
                  value={{ notifications, setNotifications }}
                >
                  <APIProvider>{props.children}</APIProvider>
                </NotificationContext.Provider>
              </CouponContext.Provider>
            </SubscriptionContext.Provider>
          </TransactionContext.Provider>
        </ProductContext.Provider>
      </PopUpContext.Provider>
    </UserContext.Provider>
  );
}

export {
  ContextStore,
  UserContext,
  PopUpContext,
  ProductContext,
  TransactionContext,
  SubscriptionContext,
  CouponContext,
  NotificationContext,
};
