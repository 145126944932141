import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { ProductContext } from "../../API";
import { getSelectStyles } from "../../API/Helpers";

export default function ProductSelect(props) {
  const { products } = useContext(ProductContext);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(makeOptions, [products]);
  useEffect(makeValue, [props.value]);

  function makeOptions() {
    const _options = [];
    products.forEach((product) =>
      _options.push({ value: product._id, label: product.title })
    );
    setOptions(_options);
  }

  function makeValue() {
    setValue(
      props.value?.map((value) => ({
        value,
        label: products.find((product) => product._id === value).title,
      }))
    );
  }

  function onChange(e) {
    props.setValue(e?.map((v) => v.value));
  }

  return (
    <Select
      className={props.className}
      options={options}
      styles={getSelectStyles()}
      placeholder={props.placeholder}
      value={value}
      onChange={onChange}
      isMulti={props.isMulti}
    />
  );
}
