import React, { useContext, useState, useMemo } from "react";
import Plot from "react-plotly.js";

import { ProductContext, TransactionContext } from "../../API";
import { ISODate, createDateRange } from "../../API/Helpers";

import { TagSelect, DateRange } from "../inputs";
import { Status } from "../misc";

const data = [
  {
    type: "scatter",
    mode: "lines+markers",
    marker: { color: "black" },
  },
];

const layout = {
  font: {
    family: "Bebas Neue",
  },
  margin: {
    t: 30,
    b: 30,
    l: 30,
    r: 30,
    pad: 30,
  },
  yaxis: {
    automargin: true,
    fixedrange: true,
    tickformat: "$",
    rangemode: "tozero",
  },
  xaxis: {
    showgrid: false,
    automargin: true,
    fixedrange: true,
    tickformat: "%B %d \n %Y",
  },
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
};

export default function DailyRevenue(props) {
  const { products, productsStatus } = useContext(ProductContext);
  const { startDate, stopDate, transactions, transactionsStatus } =
    useContext(TransactionContext);

  const [tags, setTags] = useState([]);
  const [time, setTime] = useState("week");

  const { total, x, y } = useMemo(() => {
    let total = 0;
    let data = Object.fromEntries(
      createDateRange(startDate, stopDate).map((date) => [ISODate(date), 0])
    );

    transactions
      .filter((t) => t.completed)
      .forEach((transaction) => {
        //if there are tags, find product, and skip for no matches
        if (tags.length > 0) {
          let product = products.filter((product) => {
            return product._id === transaction.sample_id;
          })[0];
          if (!product?.tags?.some((el) => tags.includes(el))) {
            return;
          }
        }

        //add element to dataset or increment revenue
        let date_purchase = new Date(transaction.purchase_date);
        let date = ISODate(date_purchase);
        if (date in data) {
          data[date] += transaction.price;
        } else {
          data[date] = transaction.price;
        }
        total += transaction.price;
      });

    return {
      total,
      x: Object.keys(data),
      y: Object.values(data),
    };
  }, [tags, time, products, transactions]);

  return (
    <div className="block">
      <div className="flex wrap mb-1">
        <h3>Daily Revenue</h3>
        <TagSelect
          placeholder={"filter by tags..."}
          value={tags}
          setValue={setTags}
        />

        <DateRange />
      </div>
      {productsStatus || transactionsStatus ? (
        <div className="stats-no-sales">
          <Status status={productsStatus || transactionsStatus} />
        </div>
      ) : !total ? (
        <div className="stats-no-sales">No sales.</div>
      ) : (
        <Plot
          data={[
            {
              ...data[0],
              x,
              y,
            },
          ]}
          layout={layout}
          className="stats-graph"
          config={{ displayModeBar: false, responsive: true }}
        />
      )}
      <div className="flex small">
        Total:
        <b>${total}</b>
      </div>
    </div>
  );
}
