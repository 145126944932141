import React from "react";

import AudioPlayer from "./AudioPlayer";

import "./DemoRow.css";

export default function DemoRow(props) {
  return (
    <div className="demo-row flex mb-2">
      <AudioPlayer audio={props.demo.audio} index={props.index} />
      <input
        spellCheck="false"
        className={props.setName ? "_editable" : ""}
        placeholder="Demo Title"
        readOnly={!props.setName}
        value={props.demo.name}
        onChange={(e) => props.setName(e.target.value)}
      />
      {props.removeDemo && (
        <button className="demo-row-remove" onClick={props.removeDemo}>
          <b>Delete</b>
        </button>
      )}
    </div>
  );
}
