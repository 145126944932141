import React, { useContext, useState, useMemo, useEffect } from "react";

import { TransactionContext } from "../API";

import { Input, Picker, DateRange } from "../components/inputs";
import { TransactionRow } from "../components/transactions";
import { Status } from "../components/misc";

import "./Transactions.css";

export default function Transactions() {
  const { transactions, transactionsStatus } = useContext(TransactionContext);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("complete");
  const [selected, setSelected] = useState(null);
  const [length, setLength] = useState(50);
  const [scroll, setScroll] = useState(null);

  const filteredTransactions = useMemo(() => {
    if (!transactions.length) return [];
    return transactions
      .filter((transaction) => {
        if (transaction.gift) return false;

        //status filter
        if (status !== "all") {
          if (status === "complete" && !transaction.completed) {
            return false;
          } else if (status === "incomplete" && transaction.completed) {
            return false;
          }
        }

        //search filter
        if (search) {
          if (
            !transaction.customer_email
              .toLowerCase()
              .includes(search.toLowerCase()) &&
            !transaction.name?.toLowerCase().includes(search.toLowerCase())
          )
            return false;
        }

        return true;
      })
      .reverse();
  }, [search, status, transactions]);

  useEffect(() => {
    window.addEventListener("scroll", () =>
      setScroll(
        window.innerHeight + Math.ceil(window.pageYOffset) >=
          document.body.offsetHeight
      )
    );
  }, []);

  useEffect(() => {
    if (scroll) showMore();
  }, [scroll]);

  function showMore() {
    setLength((prevLength) =>
      prevLength + 50 < filteredTransactions.length
        ? prevLength + 50
        : filteredTransactions.length
    );
  }

  function select(id) {
    selected === id ? setSelected(null) : setSelected(id);
  }

  return (
    <div className="container _transactions">
      <h1 className="mb-2">Transactions</h1>
      <div className="block">
        <div className="flex wrap mb-2">
          <Input
            className="grow"
            name={`Search [${filteredTransactions.length}]`}
            type="search"
            value={search}
            setValue={setSearch}
          />
          <DateRange />
          <Picker
            options={["all", "complete", "incomplete"]}
            option={status}
            setOption={setStatus}
          />
        </div>
        {transactionsStatus ? (
          <center>
            <Status status={transactionsStatus} />
          </center>
        ) : !filteredTransactions.length ? (
          <center>No transactions found.</center>
        ) : (
          <>
            <div className="grid _transactions mb-1">
              <div className="grid-row _header">
                <h4>Status</h4>
                <h4>Total</h4>
                <h4>Customer</h4>
                <h4>Email</h4>
                <h4>Date</h4>
              </div>
              {filteredTransactions.slice(0, length).map((transaction) => (
                <TransactionRow
                  key={transaction._id}
                  transaction={transaction}
                  setSearch={setSearch}
                  setSelected={select}
                  selected={selected}
                />
              ))}
            </div>
            {length < filteredTransactions.length && (
              <>
                <center>
                  <button className="mb-1" onClick={showMore}>
                    <b>Show More</b>
                  </button>
                </center>

                <div className="grid-length">
                  {length}/{filteredTransactions.length}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
