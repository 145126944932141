import React, { forwardRef } from "react";

export default forwardRef(function StatusButton(props, ref) {
  const disabled = props.disabled || props.status;

  return (
    <button
      ref={ref}
      {...props}
      className={props.className}
      disabled={disabled}
      onClick={(e) => {
        !disabled && props.onClick && props.onClick(e);
        disabled && e.stopPropagation();
      }}
    >
      {props.status ? (
        <div className="flex center">
          {props.error && <div className="error">{props.error}</div>}
          <div className="status">{props.status}</div>
        </div>
      ) : (
        props.text
      )}
    </button>
  );
});
