import React, { Fragment } from "react";

export default function Status(props) {
  return !(props.error || props.status || props.progress) ? null : (
    <div className="status-container">
      {props.error && <div className="error">{props.error}</div>}
      {props.status && <div className="status">{props.status}</div>}
      {!!props.progress && (
        <div
          className="progress"
          style={{
            background: `linear-gradient(to right, black ${props.progress}%, rgba(0,0,0,0) 0%)`,
          }}
        />
      )}
    </div>
  );
}
