import React, { useContext, useRef, useEffect } from "react";

import { PopUpContext } from "../../API";

import { Status } from "../misc";
import ScrollLock from "./ScrollLock";

import "./PopUp.css";

export function PopUpRender() {
  const { popUp, setPopUp } = useContext(PopUpContext);
  return popUp ? <PopUp popUp={popUp} close={() => setPopUp()} /> : null;
}

export function PopUp({ popUp, children, close }) {
  const container = useRef();
  const button = useRef();

  function onClose() {
    close && close();
    popUp?.close && popUp.close();
  }

  return !(popUp || children) ? null : (
    <div
      id="pop-up"
      className="mask flex"
      onClick={close}
      ref={container}
      onKeyPress={(e) => {
        if (e.key === "Enter") button.current?.click();
      }}
    >
      <ScrollLock />
      <div id="pop-up-container">
        <div id="pop-up-wrapper" onClick={(e) => e.stopPropagation()}>
          {(close || popUp?.close) && (
            <button className="pop-up-close" onClick={onClose}>
              Close
            </button>
          )}
          {popUp?.title && <h2 className="mb-2">{popUp.title}</h2>}

          {popUp?.message && <p className="mb-2">{popUp.message}</p>}

          <Status status={popUp?.status} />

          {popUp?.buttonText && popUp?.cb && (
            <button className="button" onClick={popUp.cb} ref={button}>
              {popUp.buttonText}
            </button>
          )}

          {popUp?.component && <popUp.component close={close} />}
          {children}
        </div>
      </div>
    </div>
  );
}
