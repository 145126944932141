import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ContextStore } from "./API";
import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ContextStore>
      <App />
    </ContextStore>
  </BrowserRouter>
);
