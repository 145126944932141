import React from "react";

import { ProductSelect } from "../inputs";
import ProductDetailsGraph from "./ProductDetailsGraph";

export default class ProductDetails extends React.Component {
  state = {
    product: null,
  };

  render() {
    return (
      <div id="stats-module-details" className="block">
        <div className="module-filter-container">
          <div className="stats-module-title">Product Performance:</div>
          <div className="stats-module-select">
            <ProductSelect
              placeholder={"Select a product..."}
              value={this.state.product}
              setValue={(product) => this.setState({ product })}
            />
          </div>
          <div
            className="stats-details-clear"
            onClick={() => this.setState({ product: null })}
          >
            clear
          </div>
        </div>

        {this.state.product && (
          <ProductDetailsGraph
            sales={this.props.sales}
            product={this.state.product.value}
          />
        )}
      </div>
    );
  }
}
