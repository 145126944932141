import React, { useContext, useState, Fragment } from "react";

import { APIContext, PopUpContext } from "../../API";

import { Status, StatusButton } from "../misc";

export default function DeleteProduct(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  function submit() {
    setError(null);
    setStatus("Deleting");

    API.deleteCoupon(props.coupon, (err, response) => {
      setStatus(null);
      if (err) {
        return setError(
          err.response?.data?.msg ||
            `Something went wrong.  "${props.coupon.code}" was not removed from your store.`
        );
      }
    });
  }

  return (
    <Fragment>
      <p className="mb-2">
        Are you sure you want to delete "{props.coupon.code}" from your store?
      </p>

      <Status error={error} />

      <div className="button-group">
        <button onClick={() => setPopUp(null)}>Cancel</button>
        <StatusButton
          className={`button _pop-up ${status && "_disabled"}`}
          status={status}
          onClick={submit}
          text="Delete"
        />
      </div>
    </Fragment>
  );
}
