import React, { useContext, useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { APIContext, PopUpContext, ProductContext } from "../API";

import { Picker } from "../components/inputs";
import { Status, StatusButton } from "../components/misc";
import {
  ProductPreview,
  InfoForm,
  ThumbnailForm,
  DemoForm,
  DemoRow,
  DeleteDemo,
  FileForm,
  FileRow,
  DeleteFile,
} from "../components/products";

export default function EditProductPage() {
  const { productSlug } = useParams();
  const { products } = useContext(ProductContext);
  const product = useMemo(
    () => products?.find((p) => p.slug == productSlug),
    [productSlug, products]
  );

  const [nav, setNav] = useState("info");

  return (
    <div className="container">
      <div className="flex wrap mb-2">
        <h1>Edit Product</h1>
        <Picker
          options={["info", "thumbnail", "demos", "files"]}
          option={nav}
          setOption={setNav}
        />
      </div>
      {!product ? (
        <Status status="Loading" />
      ) : (
        <EditProduct product={product} nav={nav} />
      )}
    </div>
  );
}

function EditProduct({ product, nav }) {
  const navigate = useNavigate();
  function close() {
    navigate("/products");
  }

  const [info, setInfo] = useState({
    ...product,
    release_date: new Date(product.release_date),
  });
  const [thumbnail, setThumbnail] = useState(product.thumbnail.location);
  const [demos, setDemos] = useState(
    product.demos.map((demo) => ({
      name: demo.name,
      audio: demo.location,
    }))
  );
  const [newDemo, setNewDemo] = useState(null);
  const [files, setFiles] = useState(product.products);
  const [newFile, setNewFile] = useState(null);

  return (
    <div className="flex wrap start center">
      <div className="block grow">
        {nav === "info" && (
          <EditInfo info={info} setInfo={setInfo} close={close} />
        )}
        {nav === "thumbnail" && (
          <EditThumbnail
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
            product={product}
            close={close}
          />
        )}
        {nav === "demos" && (
          <EditDemos
            demos={demos}
            setDemos={setDemos}
            newDemo={newDemo}
            setNewDemo={setNewDemo}
            product={product}
            close={close}
          />
        )}
        {nav === "files" && (
          <EditFiles
            files={files}
            setFiles={setFiles}
            newFile={newFile}
            setNewFile={setNewFile}
            product={product}
            close={close}
          />
        )}
      </div>
      <ProductPreview
        info={info}
        thumbnail={
          typeof thumbnail === "string"
            ? thumbnail
            : URL.createObjectURL(thumbnail)
        }
        demos={newDemo ? [...demos, newDemo] : demos}
        files={newFile ? [...files, newFile] : files}
        className="grow"
      />
    </div>
  );
}

function EditInfo(props) {
  const API = useContext(APIContext);

  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  function submit() {
    setError(null);

    if (!props.info.title) {
      return setError("Missing title");
    }

    if (!props.info.price) {
      return setError("Missing price");
    } else if (typeof props.info.price !== "number") {
      return setError("Invalid price");
    }

    if (!props.info.type) {
      return setError("Missing type");
    }

    if (!props.info.release_date) {
      return setError("Missing Release Date");
    }

    if (!props.info.slug) {
      return setError("Missing URL");
    } else if (!props.info.slug.match(/^\w+([-_]\w+)*$/)) {
      return setError("Invalid URL");
    }

    if (props.info.tags.length === 0) {
      return setError("Missing Tags");
    }

    setStatus("Submitting");
    API.editProduct(props.info, (err, response) => {
      setStatus(null);
      if (err) {
        return setError(err.response?.data?.msg || "Something went wrong");
      }
      props.close && props.close();
    });
  }

  return (
    <>
      <h2 className="mb-2">Edit Info</h2>
      <InfoForm info={props.info} setInfo={props.setInfo} />
      <Status error={error} />
      <StatusButton
        text="Submit"
        className="button"
        status={status}
        onClick={submit}
      />
    </>
  );
}

function EditThumbnail(props) {
  const API = useContext(APIContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(null);

  function imageSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      if (e.target.files[0].type.substring(0, 5) !== "image") {
        return setError("Invalid thumbnail file type.");
      }

      props.setThumbnail(e.target.files[0]);
    }
  }

  useEffect(() => {
    console.log(props.thumbnail);
  }, [props.thumbnail]);

  function submit() {
    setError(null);

    if (!props.thumbnail) {
      return setError("Missing Thumbnail");
    }

    setStatus("Uploading");
    API.changeThumbnail(
      props.product,
      props.thumbnail,
      setProgress,
      (err, response) => {
        setStatus(null);
        setProgress(null);
        if (err) {
          return setError(err.response?.data || "Something went wrong");
        }
        props.close && props.close();
      }
    );
  }

  return (
    <>
      <h2 className="mb-1">Edit Thumbnail</h2>
      <ThumbnailForm
        thumbnail={
          typeof props.thumbnail === "string"
            ? props.thumbnail
            : URL.createObjectURL(props.thumbnail)
        }
        onChange={imageSelectHandler}
      />
      <Status error={error} progress={progress} />
      <StatusButton
        text="Submit"
        status={status}
        className="button"
        onClick={submit}
      />
    </>
  );
}

function EditDemos(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(null);

  function audioSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      if (e.target.files[0].type.substring(0, 5) !== "audio") {
        return setError("Invalid demo file type.");
      }

      props.setNewDemo({
        name: e.target.files[0].name.split(".")[0],
        audio: e.target.files[0],
      });
    }
  }

  function addDemo() {
    setError(null);

    if (!props.newDemo) {
      return setError("Missing Demo");
    } else if (props.newDemo.name === "") {
      return setError("Missing Demo Name");
    }

    setStatus("Uploading");
    API.addDemo(props.product, props.newDemo, setProgress, (err, response) => {
      setProgress(null);
      setStatus(null);
      if (err) {
        return setError(err.response?.data?.msg || "Something went wrong");
      }
      props.close && props.close();
    });
  }

  function removeDemo(i) {
    setPopUp({
      title: "Delete Demo",
      component: () => (
        <DeleteDemo
          product={props.product}
          demo={props.demos[i]}
          close={props.close}
        />
      ),
    });
  }

  return (
    <>
      <h2 className="mb-2">Edit Demos</h2>
      <DemoForm
        demos={props.demos}
        removeDemo={removeDemo}
        onChange={!props.newDemo && audioSelectHandler}
      />
      {props.newDemo && (
        <div className="demo-form">
          <DemoRow
            index={props.demos.length + 1}
            demo={props.newDemo}
            setName={(name) => props.setNewDemo({ ...props.newDemo, name })}
            removeDemo={() => props.setNewDemo(null)}
          />
          <Status error={error} progress={progress} />
          <StatusButton
            text="Submit"
            status={status}
            className="button"
            onClick={addDemo}
          />
        </div>
      )}
    </>
  );
}

function EditFiles(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(null);

  function fileSelectHandler(e) {
    setError(null);

    if (e.target.files[0]) {
      props.setNewFile(e.target.files[0]);
    }
  }

  function addFile() {
    setError(null);

    if (!props.newFile) {
      return setError("Missing Files");
    }

    setStatus("Uploading");
    API.addFiles(props.product, props.newFile, setProgress, (err, response) => {
      setProgress(null);
      setStatus(null);
      if (err) {
        return setError(err.response?.data?.msg || "Something went wrong");
      }
      props.close && props.close();
    });
  }

  function removeFile(i) {
    setPopUp({
      title: "Delete File",
      component: () => (
        <DeleteFile
          product={props.product}
          file={props.files[i]}
          close={props.close}
        />
      ),
    });
  }

  return (
    <>
      <h2 className="mb-2">Edit Files</h2>
      <FileForm
        files={props.files}
        removeFile={removeFile}
        onChange={!props.newFile && fileSelectHandler}
      />
      {props.newFile && (
        <div className="file-form">
          <FileRow
            number={props.files.length + 1}
            file={props.newFile}
            removeFile={() => props.setNewFile(null)}
          />
          <Status error={error} progress={progress} />
          <StatusButton
            text="Submit"
            status={status}
            className="button"
            onClick={addFile}
          />
        </div>
      )}
    </>
  );
}
