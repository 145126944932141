import React, { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import { CouponContext } from "../API";

import { Input, Picker } from "../components/inputs";
import { CouponRow } from "../components/coupons";

import "./Coupons.css";

export default function Coupons() {
  const { coupons } = useContext(CouponContext);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");

  const filteredCoupons = useMemo(() => {
    if (!coupons.length) return [];

    let now = new Date();
    return coupons.filter((coupon) => {
      if (status !== "all") {
        let start = new Date(coupon.startDate);
        let end = new Date(coupon.endDate);
        if (status === "active" && (now < start || now > end)) {
          return false;
        } else if (status === "expired" && now < end) {
          return false;
        }
      }

      // search filter
      if (search) {
        if (!coupon.code.includes(search)) return false;
      }

      return true;
    });
  }, [search, status, coupons]);

  return (
    <div className="container _coupons">
      <div className="flex mb-2">
        <h1>Coupons</h1>
        <Link to="/coupons/add" className="flex small hover">
          <div className="icon plus" />
          Add Coupon
        </Link>
      </div>
      <div className="block">
        <div className="flex wrap mb-2">
          <Input
            className="grow"
            name={`Search [${filteredCoupons.length}]`}
            type="search"
            value={search}
            setValue={setSearch}
          />
          <Picker
            options={["all", "active", "expired"]}
            option={status}
            setOption={setStatus}
          />
        </div>

        {!filteredCoupons.length ? (
          <center>No products found.</center>
        ) : (
          <div className="grid _coupons">
            <div className="grid-row _header">
              <h4>Status</h4>
              <h4>Coupon</h4>
              <h4>Start</h4>
              <h4>End</h4>
            </div>
            {filteredCoupons.map((coupon) => (
              <CouponRow key={coupon._id} coupon={coupon} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
