import "./Stepper.css";

export default function Stepper(props) {
  return (
    <div className="stepper">
      {props.steps.map((step, i) => (
        <div
          key={i}
          className={`stepper-step ${
            props.step >= i ? "_active" : "_inactive"
          } ${props.step === i && "_current"}`}
        >
          {step}
        </div>
      ))}
    </div>
  );
}
