import { useRef } from "react";

import "./Forms.css";

export default function ThumbnailForm(props) {
  const imageField = useRef();

  return (
    <center className="mb-2">
      {props.thumbnail && (
        <img
          className="mb-2"
          width="240"
          height="240"
          src={props.thumbnail}
          alt=""
        />
      )}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={props.onChange}
        ref={imageField}
      />
      <button onClick={() => imageField.current.click()}>
        {props.thumbnail ? "Change Thumbnail" : "Upload Thumbnail"}
      </button>
    </center>
  );
}
