import React, { useContext, useState, Fragment } from "react";

import { APIContext, PopUpContext } from "../../API";

import { Status, StatusButton } from "../misc";

export default function DeleteFile(props) {
  const API = useContext(APIContext);
  const { setPopUp } = useContext(PopUpContext);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  function submit() {
    setError(null);
    setStatus("Deleting");
    API.deleteFiles(props.product, props.file.name, (err, response) => {
      props.close && props.close();
      if (err) {
        setStatus(null);
        return setError(
          err.response?.data?.msg ||
            `Something went wrong.  "${props.file.name}" was not removed from ${props.product.title}.`
        );
      }
    });
  }

  return (
    <Fragment>
      <p className="mb-2">
        Are you sure you want to delete "{props.file.name}" from{" "}
        {props.product.title}?
      </p>
      <Status error={error} />
      <div className="button-group">
        <button onClick={() => setPopUp(null)}>Cancel</button>
        <StatusButton
          className="button"
          status={status}
          onClick={submit}
          text="Delete"
        />
      </div>
    </Fragment>
  );
}
