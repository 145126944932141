import React, { useContext, useState, useMemo } from "react";
import Plot from "react-plotly.js";

import { ProductContext, TransactionContext } from "../../API";

import { DateRange, TagSelect } from "../inputs";
import { Status } from "../misc";

import "./ProductComparison.css";

const data = [
  {
    type: "bar",
    marker: {
      color: "black",
    },
  },
];

const layout = {
  font: {
    family: "Bebas Neue",
  },
  margin: {
    t: 30,
    b: 30,
    l: 30,
    r: 30,
    pad: 30,
  },
  yaxis: {
    automargin: true,
    fixedrange: true,
    tickformat: "$",
    rangemode: "tozero",
  },
  xaxis: {
    showgrid: false,
    automargin: true,
    fixedrange: true,
    showticklabels: false,
  },
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  bargap: 0.3,
};

export default function ProductComparison() {
  const { products, productsStatus } = useContext(ProductContext);
  const { transactions, transactionsStatus } = useContext(TransactionContext);

  const [tags, setTags] = useState([]);
  const [time, setTime] = useState("week");
  const [tableEntries, setTableEntries] = useState(5);

  const { table, total, x, y } = useMemo(() => {
    let p_data = {};
    let t_data = {};
    let total = 0;

    transactions
      .filter((t) => t.completed)
      .forEach((transaction) => {
        transaction.urls.forEach((url) => {
          //find product
          let product = products.find((product) => {
            return product._id === url.sample_id;
          });

          //no product found, possibly deleted
          if (!product) return;

          //skip if tags selected and none match
          if (tags.length > 0 && !product.tags?.some((el) => tags.includes(el)))
            return;

          if (product.title in p_data) {
            p_data[product.title] += product.price;
            t_data[product.title]["revenue"] += product.price;
            t_data[product.title]["sales"]++;
          } else {
            p_data[product.title] = product.price;
            t_data[product.title] = {
              title: product.title,
              revenue: product.price,
              sales: 1,
            };
          }
          total += product.price;
        });
      });

    p_data = Object.fromEntries(
      Object.entries(p_data).sort((a, b) => a[1] - b[1])
    );
    t_data = Object.fromEntries(
      Object.entries(t_data).sort((a, b) => b[1].revenue - a[1].revenue)
    );
    return {
      table: Object.values(t_data),
      total,
      x: Object.keys(p_data),
      y: Object.values(p_data),
    };
  }, [tags, time, products, transactions]);

  return (
    <div className="block">
      <div className="flex wrap">
        <h3>Product Comparison</h3>
        <TagSelect
          placeholder={"filter by tags..."}
          value={tags}
          setValue={setTags}
        />
        <DateRange />
      </div>

      {productsStatus || transactionsStatus ? (
        <div className="stats-no-sales">
          <Status status={productsStatus || transactionsStatus} />
        </div>
      ) : !total ? (
        <div className="stats-no-sales">No sales.</div>
      ) : (
        <>
          <Plot
            data={[
              {
                ...data[0],
                x,
                y,
              },
            ]}
            layout={layout}
            className="stats-graph"
            config={{ displayModeBar: false, responsive: true }}
          />
          <div className="grid _product-comparison mb-1">
            <div className="grid-row _header">
              <span />
              <h4>Product</h4>
              <h4>Sales</h4>
              <h4>Revenue</h4>
            </div>
            {table.slice(0, tableEntries).map((product, i) => (
              <div className="grid-row" key={product.title}>
                <span>{i + 1}</span>
                <span>{product.title}</span>
                <span>{product.sales}</span>
                <span>${product.revenue}</span>
              </div>
            ))}
          </div>
          {table.length > tableEntries && (
            <center>
              <button
                onClick={() => setTableEntries(tableEntries + 5)}
                className="mb-1"
              >
                <b>Show More</b>
              </button>
            </center>
          )}
        </>
      )}

      <div className="flex small">
        Total:
        <b>${total}</b>
      </div>
    </div>
  );
}
