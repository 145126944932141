import React, { useRef } from "react";

import "./Inputs.css";

export default function InputWrapper({ label, ...props }) {
  return label ? (
    <div className={props.className}>
      <label className="mb-half" htmlFor={props.name}>
        {props.name}
      </label>
      <Input {...props} />
    </div>
  ) : (
    <Input {...props} />
  );
}

function Input(props) {
  const input = useRef();

  return (
    <div
      className={`input flex ${props.className}`}
      ref={props.setRef}
      onClick={() => input.current.focus()}
    >
      {props.type === "search" && <div className="input-icon _search" />}
      <input
        id={props.name}
        className="input-field"
        placeholder={props.placeholder || props.name}
        value={props.value}
        autoComplete={
          props.type === "name"
            ? "name"
            : props.type === "email"
            ? "email"
            : props.type === "password"
            ? "current-password"
            : undefined
        }
        type={props.type}
        onChange={(e) => props.setValue(e.target.value)}
        onBlur={props.onBlur}
        ref={input}
      />
    </div>
  );
}
