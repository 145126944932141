import React from "react";

import "./Picker.css";

export default function Picker(props) {
  return (
    <div className={`picker flex ${props.className}`}>
      {props.options.map((option) => (
        <button
          key={option}
          className={`picker-option hover ${
            props.option === option && "_selected"
          }`}
          onClick={() => props.setOption(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
