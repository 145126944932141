import { useEffect, useState } from "react";

import { getYoutubeId } from "../../API/Helpers";

export default function EmbedPlayer(props) {
  const [id, setId] = useState(false);

  useEffect(() => setId(getYoutubeId(props.url)), [props.url]);

  return !id ? (
    <div className={props.className}>
      <div className="error">Invalid Youtube Link</div>
    </div>
  ) : (
    <div className={props.className}>
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          width: "100%",
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={`https://www.youtube.com/embed/${id}${props.options || ""}`}
          frameBorder="0"
        />
      </div>
    </div>
  );
}
