import React, { useState, useRef, useEffect } from "react";
import autosize from "autosize";

import { StatusButton } from "../misc";

export default function GetMailingList(props) {
  const [string, setString] = useState(null);
  const [status, setStatus] = useState(null);

  const textarea = useRef();

  useEffect(() => {
    autosize(textarea.current);
  }, []);

  useEffect(() => {
    if (!props.users.length) return;
    const string = props.users.map((u) => `${u.name} <${u.email}>`).join(", ");
    setString(string);
  }, [props.users]);

  function downloadTxt() {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([string], { type: "text/plain" })
    );
    a.setAttribute("download", "es_email_list.txt");
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  function downloadCsv() {
    let csv = "email,name";
    props.users.forEach((user) => {
      csv += "\n";
      csv += user.email;
      csv += ",";
      if (user.name && user.name !== user.email) csv += user.name;
    });

    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
    a.setAttribute("download", "es_email_list.csv");
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  return (
    <>
      <StatusButton
        onClick={downloadTxt}
        text="Download .txt"
        status={status}
      />
      <StatusButton
        onClick={downloadCsv}
        text="Download .csv"
        status={status}
      />
    </>
  );
}
